
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexyNIXRQM9vuLEzfeUaRJAACbD5bYDHGJ_456g1TyVik5qUMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/index.vue?macro=true";
import { default as loginVy0_45atgdtwM9AO3ayutqJBo45njoEbLXfos4dwFV8GcMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifyFh6zexlvj9hkY8HxjMZ5IlNl9xpljcknzUlyJMbTC8MMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemapXzkNJD5XDIJ6ZBcxoFXspBRkOYWl7G2n6Ea8kodPym8Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45uspiQ6vUI663ZXC0OS_18R5Y8gMKFKCbz0O4IJrWNDWpcMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerfgoEG5XQaEI5T_x5CbxtOOICig3y_45bI20xXl859f3GcMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaintsvYpx56NrT3tfD7AvMp6L_6EGXBThIzHixkL4amlYpB0Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usKYeeBJwnktkKmVyfpP89wNrmituqUh3INErhBDq6_XUMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexwDDRwfaBGAJ98dteAShV_mP8WU4LOmAD1Z3bRo1WKx8Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indexeE_45ymTzwFnJder_45lAUBc56OQYbRIm1GPat1CXwN1yzwMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93ZqqRnDlAl9JC8L1M3LSVFhbxW5BD__45uX9Zsv6EwnlLkMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsS1lWLcmfpjJk4b7Z6tzoExMH_AP78njkkNaykWMpAoIMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45useVA65kQYoFWpJTk6qZ7gVcymNTMywo1gBpzAI_zEqYBwMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as indextshM9Gyij9Q4ArxY12My_45jCN6VZkK20fA5_45Q_45v2oJcYMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policy69dW0FG0eXtOPEmkG_5be72d510CmAEZ1PiFSJRUANQMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordErI4O85ZtbDSiwW1cpcM2Aei9xX0Gi1UFSAjHyVkn2IMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexoyjCQmHxqwPq03wrekBKzZuVS5MpCmL9VeaWA6vu4aUMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as index13KFPNxevQ_45fiOlfdxqn74r_45ylgraRonabW8VbSav0oMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordaS41ZHEc0fVZoW4tkO5hUtpUH1DFTnRpYnsdOFAOmuoMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as indexaR83DMGKoqTVZXyMBpoIeQTG9Qn9LzqZhNIfNriBxrwMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93aNp_4Av14NX1bOvdRkClaXWpUnYhLrPeXxKOIUxTrnAMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guidesTJzePdSkwAy4V9yR6CB6GwsFi3xOCNDVfVYpZmJR34sMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticewxbduyMfsMRsR32icnCVBbCA1wOZ0fyz4j4y_BYMeAIMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexiYtucV81fJ04w0OYBC4gNmj1KqnUu__MyoBktwa3WEMMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45password_1jCtQ0svdU0QiJL0d_fYmXxgxMYVr5n3R1KkOp_453PQMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backPRqHo3tiFWWOHzL55wStzSh3MM00qAKOPtpLQ_45Et1mYMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergeRVhPQ1hvDSo9LVb6J_cyK4HPPNFia2swkJ_45BWTCr_45McMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexSHqa0BbvAAULyILeT1cMVt3z1S9TkIlvfQYiwR5OP_45cMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93m1paD3CuWZTuOYenchTR28KubGb_45BcBX9RH9iYXdaKsMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youl20eEoB2xkjdqztVeNr62_bku46xJmC0Tz1qOLBNbMsMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as indexlxS6d7_4581YA5tBdSviSxlfIZxwoQLsgpm38iT_jR7OwMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexdKMjkOZM8x1ym1EiZKXQPtGJ4iS_jYTnNunarg_obBIMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93xU4aYeaKHK3iBMlRqr_45z0M9W7nsMMI6HvRK3n32yiEEMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as index_4531VhicaHcggqJ6jhbE16Ad_LnEQ4nKRUKfdZoSaU0oMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexkLfOTmNc0HW7DKiM0FT3PeOjWYPp3ErN8B5anf465yMMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93THGcYlmPY1huZ_45gPG1Qc_45symB5GCEms4O0oiHjLyxU4Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbacksdjMMh3u7VzA9M_45MyOtuHIx2m6_B8YiWQldvStG1EtYMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimpW1P3qBQ_S9jxgqCfr1QE_AJ3YTk_keER6axkQNWV48Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as indexb4sSvSE_vTdAZu8wj2aCPwe_6lNQ_45zZrV_45rUfdTrU6sMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youjU18phthUq2sx4y__45morCgoO3bCQ1Oq7bV6Gp_ty4D0Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errorb9pKLJIS9RWZ14J8p_dtJdrLd5QbATh8P4qIO_J6MM8Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as index8_45FJT4zAishYkG_wUqDJgYPOM9MascWMm_MHU5znDSUMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indexkqlTMzCzPmf2yYqzvZzgacvxd6fiCj9WOerUxXqQugkMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as erroreoGBWEepfRc7PXaP3x6TT3tVjuOM5KwwFF0S877X86AMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93EVGyVW7Cwg_5oYqp6_45KKmkld_gxe3FjOu_45QxKhQ75dAMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as details45C1xhMbV3TcAF09mHZyKIMPbN3lhIcuKjGasN8uQRUMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45ratesiQcJqrQK_45oPrFChpzkB0_45otIKg7VUvtmUryJKWHUizAMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93wb8vxFHa2TQZVXr6NBwWmPnavdnHRHlU91qyTrYW_x0Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexmYdc7EerKYs_45fJLavawLz814WVDNImfoVmCagWkSu6oMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settingsCdnD6rZSsDAe2R_4M0FZeuH2ERhwNqTqybjsDGRhTmcMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexZcGAOxc21xK3I13VC7YckYpWHqJ0Qz2IeQOkd6pkXz0Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chat_Ch_45xTxqr91QnSLOLyP4PBWAhSmiHMuDALtaoqKfSnUMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45saleedKdXhJz_459IFzZH5aSDL_45Awp_45A2DHlPnZGXA4jD_45beQMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youznUtTSHNfkA1KOfN1NcE4Sdq3Ql9x158AsN9pwIKqzoMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensiveP6GII3GykzBpSIp1o2b_v_45nTFsvfA9PENcyPKk77aeAMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93GfnzU7GzEa1ZQ20B6MweHADzYkULtmgf92R6mpEAiJ8Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as add0_455_451UNlJSpzvj5E9A0r8ZoDUvfRz1uL3pUJ164ZB04Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexxA4YjqWDko7QKjj3gw2xG0cVARCDHniHesXATIB7j90Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93rE6L7jD6hVYkdGeITub5_zvtBdKBROW5DM2Qqz3KR2kMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callback_45NIdcc9EChkHv0_NPIlDqEvESTrJzAvBfEUcvjyVlY8Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93SfuGxUN0noHnnlCn0HTSFW1vnUiuDa61DI9agQN9CCcMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youp81jiSfHc91kLoulO5YKUKLU52p96YAbu2aPoEI2zlUMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45b75FQpmXbGvpk1M15MXKRlY8066mNjVPW6qkHki1hhlgMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexjTZjr4M6mcHrVNWIuV5ivzxaLqxTdjQxji8QV_452Hx7wMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93tP4SzTXc62xO8EF4zFgEKN22ao48Dpck9lX45PswiScMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_9385K4Pb6Ql_45cE98ei2LXDhqz3osEDMLDVmhzxPdvBcEAMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexBZkIZjiW1M1ixnl2XdCGGsIJ2d53g21MeJXKPwjsy0YMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addQ_jkMCc0_eYn__65ES4kPFLCnsekIenXm5MBg_Rcb30Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93J3vYndL1atJLfdfeU2goPUB0rX4IughJo9RHFsFSpPoMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailsYDNYemRFxyaQgSm8VxAqUPzWwGsT2cC8wSfxSl1kP_kMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as digital_45eicrdxdJiD98jwqM_45SxkTg2E1DhYsoGNR1XRQmO83JJAHAMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45youf5VEu9AJf484noU3cD4dVvzTi1a6KFiR_45yPs0j7FJBoMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93BcS0KAw4M59bqq8eSxr8BjuOfH6K2_45BhDm3yx8B0yLgMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93Tyb75YJhHz_Xtbw8treqwH_45tfObUfnFHD3g9dBLCEyEMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as step0te0cszicfnFg3pUvTtiWjddBBs6gTnWwXEItDDpPiyMMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue?macro=true";
import { default as detailsuWwX8A558MK_45tUEBRUMumXNPqLo4lVRCriGCkOM5_45lIMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsnfU3_vOY_45sNhPyv8El_45Ps5ZqVBLLXysAAUJ4Xx_45oe5cMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexWduGQlgqI_xtjLpqyK818tmmbOxqb5PXW_hYuZgjYVcMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signupPAT7txENF_45rOE8gti05KlnExWtX245_45jlb6kF9ikRGgMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youYE65WloJFAaBD2AKI1dcirmfXVRpFgv6Vjea761hFKMMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93oP7lBNV1UTo5CUWfj8msoKwReGYw7IPRBzB50vx_45h1MMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as _91step_93faGxEfdPB88V3wkaQUyLym7EW9f5mXUCCq_45UxbCl4lUMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93vcW1mHZMaBx3mSSlqWzekHPy_45a04_450Tuf5wC_45ulDiRwMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0CaVD0fDC75SUGnfcsGGuMaes1y5Gvl7eax_45_45jllWC3YMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customers1nRgmDotX9kW_45F3PFr9F0B4FcDOmk00agQCzCxfLdz8Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youuTqjvVcGdhtCqP1PwA2bW8wjmA6_y__456U45Kbcq810MMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as details_w9xquzSdFlD1CMsypnrVd4huBSWMmfBgQkX1isDYQQMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as details20Mr_bPvJFK7Dpv_Qy_RoXZdrP7NoAFxDvOpuySngX0Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_939A85krztMeiCmdmQcpKjH4gYYWC_5JeoY8ugPpjXs64Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebatexVyc3JWU4M9FbaGWNcp96u_1NKokmvbcEaYnZhUQtOMMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93V4JtxDKk2rMW1mRYj_BKA06538SUj515uIUgU1TA_45DMMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsxsTEpzrX_45tT1xjs0cWpLZzPdutgOL5CvITCzsAHTJHUMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0OTiqYl1n_mNOAQVuniyaknFHuxiot42mlSJM2fVuqqYMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexSvZLUayxx4TveOw9UFP7Rr_SH2mPMMcZfW8ftv6YH4EMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexanjQAjkcxEv7kAq3hPswGCpq_45mhybsMOtYR4v7ZtR08Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailsz3uywwTijSloEmJlvXaej8HkcvudWAsJ6ZxVWfEmrz4Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionsMNcjcUHSgfIFrMcVcomvbLWuM4wJAnyjix1HsEoZXy4Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as indexHsoUOdTOoxhvaP3iglxuzp4sIe78rIXEHCfbpNBQ3p4Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as details70ii5latHq6h2_P4EMn89Rou1iNPjKUWXiYwBmNEHUgMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as index7moFPh7ToiEo0sr8fWMJLos52nzMaaiWLckF4TkGuakMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successjfFcdlcljhJCtl3HMpR0WCRd1_BvbuKYGybQJEEO1osMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offerWiZLHfS0dsvKTD8sKQHdszjYyd0CAk2bwDA26O7oe24Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsRpuB3UBshJLMYSXymp9rGianO_45Bna4iARm126kmIWqoMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbourWXY_3A_lCFGUZJxElUIdl22NGHM2iKPPz0P6fy69QZQMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93ZfR3ClLracb52Bu_45VBhjIRTryv5_9fiVmXRdnnYh2KUMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexoC58GTE_9gaJIdMRsPDN2db_opnPnN3wvJMh9QSxf8UMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_934juj5DFVGFbWpgyuaSZdN3Fxpf_45TMOEOLw0LXMFTrAcMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexTY2mPrgcRfDlqjqMLYGh2K3D8Aw2SK3au1_aheWZIH4Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_933OXzObPRothgq3zpy7T8iUYrrihjZUQ9Oa5kty8ohdsMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as index4VOtqPk84rLXH9meAyt_452poe89McmSEizjJUsfngmnQMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexSFvqnrR3ngqTugvpDe_45WP0B8_xk56doZRSGhmi8PuWIMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsXTNabzWHxW_45bhJn1zCZhgvzq9yYO_45H2KUMZlZmLvgkAMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expired_45b40McZep02YPnlY4xCnwxC7_45COCAwBhsvQvKKK1aQkMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexP7WlpCXtdsMNy2mryFJfmJLwY0UNd2f03FuJBszUDfIMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45you_fGW7_45g2LdA2sSNfkje1dgkaUC0G7epFHGY0BnCJc90Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailswDGgvkG3GuJqrt2NVbiJO7GoXZZcRoMK8_OJRg5b2lIMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as confirmationzHl7p1a0pIrN5N3bts3BSv_4D9f5i6KlR_45DVej699hsMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionJ5wxco7wY2ksU1cpJiDIhhsFYWvPWErL91KVxGmtMGwMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultsHGMVaboLR9kPnvnwcQYxCVF1j9qeaehSu_45saldecWzoMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationabuxJ9MSnNZuq_456gMZR6PY3Tt6r0NDuf9KpoQSYIy_oMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93hi6YbeucbCCcueQ235YT_b2uTOvKuYvhrrEJ46Fyb70Meta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93XFLAcsRPGwVgAlFIA211vXvO2OpSo6__45fcNh1VTYgKUMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93PAX8C9N5CPvRE3bfqSv3U75jm_45RRZrBVU0f_iD24nlQMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93muNc1quF7ACXmU5LdvFRO7NCLYEtTUQ1V_45RRynISHGoMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as indexH8VwAQe_usY73UQ7VWn4mkJ8fFaTKtcdwuItSqbfkpAMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailsrbW8D6TS0RYzKgzSkyY_HGyU19SJ_45pvbGGwqpC497sgMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93jjKdViXiEbIL6hsHYarFxdb3H4dSl3rM858fVz2X69gMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_93zUGNkzhPKpyzkzSX8MACSepycGaUXg80kG4v2SbdqAQMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexkDR3tyUmMl3Rt7vxJYHOO0qSfog16kt9ev0zkfFvh2QMeta } from "/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginVy0_45atgdtwM9AO3ayutqJBo45njoEbLXfos4dwFV8GcMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerfgoEG5XQaEI5T_x5CbxtOOICig3y_45bI20xXl859f3GcMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordErI4O85ZtbDSiwW1cpcM2Aei9xX0Gi1UFSAjHyVkn2IMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: index13KFPNxevQ_45fiOlfdxqn74r_45ylgraRonabW8VbSav0oMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordaS41ZHEc0fVZoW4tkO5hUtpUH1DFTnRpYnsdOFAOmuoMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45password_1jCtQ0svdU0QiJL0d_fYmXxgxMYVr5n3R1KkOp_453PQMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93m1paD3CuWZTuOYenchTR28KubGb_45BcBX9RH9iYXdaKsMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: index_4531VhicaHcggqJ6jhbE16Ad_LnEQ4nKRUKfdZoSaU0oMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexb4sSvSE_vTdAZu8wj2aCPwe_6lNQ_45zZrV_45rUfdTrU6sMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexkqlTMzCzPmf2yYqzvZzgacvxd6fiCj9WOerUxXqQugkMeta?.props ?? {"verificationNeeded":true},
    meta: indexkqlTMzCzPmf2yYqzvZzgacvxd6fiCj9WOerUxXqQugkMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsCdnD6rZSsDAe2R_4M0FZeuH2ERhwNqTqybjsDGRhTmcMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexZcGAOxc21xK3I13VC7YckYpWHqJ0Qz2IeQOkd6pkXz0Meta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callback_45NIdcc9EChkHv0_NPIlDqEvESTrJzAvBfEUcvjyVlY8Meta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-form-step0",
    path: "/life-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebatexVyc3JWU4M9FbaGWNcp96u_1NKokmvbcEaYnZhUQtOMMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailsz3uywwTijSloEmJlvXaej8HkcvudWAsJ6ZxVWfEmrz4Meta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerWiZLHfS0dsvKTD8sKQHdszjYyd0CAk2bwDA26O7oe24Meta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsRpuB3UBshJLMYSXymp9rGianO_45Bna4iARm126kmIWqoMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93ZfR3ClLracb52Bu_45VBhjIRTryv5_9fiVmXRdnnYh2KUMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionJ5wxco7wY2ksU1cpJiDIhhsFYWvPWErL91KVxGmtMGwMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93hi6YbeucbCCcueQ235YT_b2uTOvKuYvhrrEJ46Fyb70Meta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93XFLAcsRPGwVgAlFIA211vXvO2OpSo6__45fcNh1VTYgKUMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93muNc1quF7ACXmU5LdvFRO7NCLYEtTUQ1V_45RRynISHGoMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailsrbW8D6TS0RYzKgzSkyY_HGyU19SJ_45pvbGGwqpC497sgMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93jjKdViXiEbIL6hsHYarFxdb3H4dSl3rM858fVz2X69gMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_93zUGNkzhPKpyzkzSX8MACSepycGaUXg80kG4v2SbdqAQMeta || {},
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src1437196925/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]